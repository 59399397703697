import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container border={true}>
          <Col>
            <Row
              justify="center"
              padding-top="15px"
              style={{ paddingTop: "20px" }}
            >
              <LogoContainer>
                <SvgIcon
                  src="Realti.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </Row>
            <Row justify="center" style={{ paddingTop: "20px" }}>
              <h5 style={{ fontSize: "45px" }}>{t("Realti")}</h5>
            </Row>
            <Row justify="center" style={{ paddingBottom: "10px" }}>
              <Col span={24} style={{ textAlign: "center", maxHeight: "30px" }}>
                <Language>{t("Address")}</Language>
              </Col>
              <Col span={24} style={{ marginTop: "10px", textAlign: "center" }}>
                75 Amherst Street
              </Col>
              <Col span={24} style={{ marginTop: "10px", textAlign: "center" }}>
                Cambridge, MA
              </Col>
              <Col span={24} style={{ marginTop: "10px", textAlign: "center" }}>
                02139
              </Col>
            </Row>
          </Col>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
